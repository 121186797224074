const aws_project_region = process.env.VUE_APP_project_region
const aws_cognito_region = process.env.VUE_APP_cognito_region
const aws_user_pools_id = process.env.VUE_APP_user_pools_id
const aws_user_pools_web_client_id = process.env.VUE_APP_user_pools_web_client_id
const oauth_domain = process.env.VUE_APP_oauth_domain
const domain = process.env.VUE_APP_domain

const awsmobile = {
    aws_project_region,
    aws_cognito_region,
    aws_user_pools_id,
    aws_user_pools_web_client_id,
    "oauth": {
        "domain": oauth_domain,
        "scope": ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        "redirectSignIn": `https://${domain}/authcb`,
        "redirectSignOut": `https://${domain}`,
        "responseType": 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        "socialProviders": ['Google']
    }
};
export default awsmobile;