<template>
  <div class="row">
    <div class="column">
      <h2>Signature Generator</h2>
      <FormulateForm v-model="formValues" @submit="handleSubmit">
        <FormulateInput name="name" label="Name" validation="required" />
        <FormulateInput name="surname" label="Surname" validation="required" />
        <FormulateInput name="phone" label="Phone" validation="required" />
        <FormulateInput type="email" name="email" label="Email" validation="required|email" />
        <FormulateInput name="position" label="Position" validation="required" />
        <FormulateInput type="submit" label="Generate Signature" />
      </FormulateForm>
    </div>
    <div class="column" style="padding: 10px; margin: 10px; width: 50%; border: 10px">
      <table width="100%" height="111.55" cellpadding="0" cellspacing="0" border="0" bgcolor="white" align="left"
        valign="top" style="padding: 0; margin: 0; border: 0;" id="signature">
        <tbody>
          <tr>
            <td>
              <table width="615" height="111.55" cellpadding="0" cellspacing="0" border="0" bgcolor="white" align="left"
                valign="top" style="padding: 0; margin: 0; border: 0;">
                <tbody>
                  <tr>
                    <td width="99.6" height="111.55" bgcolor="white">
                      <img src="ct-sig-logo.png" width="99.6" height="111.55">
                    </td>
                    <td width="28" height="111.55" bgcolor="white" cellpadding="0" cellspacing="0" border="0"
                      style="padding: 0; margin: 0; border: 0;">

                    </td>
                    <td width="auto" align="center" valign="middle" height="111.55" bgcolor="white" cellpadding="0"
                      cellspacing="0" border="0" style="padding: 0; margin: 0; border: 0;">
                      <table width="auto" height="76" bgcolor="white" cellpadding="0" align="left" valign="middle"
                        cellspacing="0" border="0" style="padding: 0; margin: 0; border: 0; display: block;">
                        <tbody>
                          <tr>
                            <td width="auto" height="19" bgcolor="white" align="right" valign="middle" cellpadding="0"
                              cellspacing="0" style="padding: 0; margin: 0; border: 0; display: block;">
                              <a width="180" height="19"
                                style="padding: 0; margin: 0; border: 0;font-family: Nunito, Calibri, sans-serif; line-height: 18px; font-size: 16px; text-decoration: none; color: #2e2666; font-weight: 700; display: block;"
                                href="https://cloudandthings.io" target="_blank">
                                <!--<img src="http://images.fishgate.co.za/cloudandthings/web-link.png" width="149" height="16" style="vertical-align: middle;"> -->cloudandthings.io</a>
                            </td>
                          </tr>
                          <tr>
                            <td width="auto" height="37" bgcolor="white" align="right" valign="middle" cellpadding="0"
                              cellspacing="0" :style="`font-size: ${fontSize(formValues.name, formValues.surname)}px;`"
                              style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, sans-serif; line-height: 37px; font-weight: 700; color: #a81763; display: block;">
                              {{ formValues.name }} {{ formValues.surname }}
                            </td>
                          </tr>
                          <tr>
                            <td width="auto" height="19" bgcolor="white" align="right" valign="middle" cellpadding="0"
                              cellspacing="0"
                              style="padding: 0; margin: 0; border: 0;font-family: Nunito, Calibri, sans-serif; line-height: 18px; font-size: 16px;color: #2e2666; font-weight: 700;display: block;">
                              {{ formValues.position }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="48" height="111.55" bgcolor="white" cellpadding="0" cellspacing="0" border="0"
                      align="center" valign="middle" style="padding: 0; margin: 0; border: 0;">
                      <img src="start-bracket.png" width="48" height="111.55"
                        style="width: 48px; height: 111.55px; display: block; vertical-align: middle; align: center;">
                    </td>
                    <td width="156" height="111.55" bgcolor="white" cellpadding="0" align="left" valign="middle;"
                      cellspacing="0" border="0" style="padding: 0; margin: 0; border: 0;">
                      <table width="156" height="60" bgcolor="white" cellpadding="0" align="left" valign="middle;"
                        cellspacing="0" border="0" style="padding: 0; margin: 0; border: 0;">
                        <tbody>
                          <tr>
                            <td width="156" height="20" bgcolor="white" align="left" valign="middle" cellpadding="0"
                              cellspacing="0"
                              style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, sans-serif; line-height: 20px; font-size: 20px;display: block;">
                              <a style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, sans-serif; line-height: 20px; font-size: 12px; font-weight: 700; color: #2e2666; text-decoration: none;"
                                :href="`tel:${formValues.phone}`"> {{ formValues.phone }}</a>
                            </td>
                          </tr>
                          <tr>
                            <td width="156" height="20" bgcolor="white" align="left" valign="middle" cellpadding="0"
                              cellspacing="0"
                              style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, sans-serif; line-height: 20px; font-size: 20px; display: block;">
                              <a style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, Calibri,sans-serif; line-height: 20px; font-size: 12px; font-weight: 700; color: #2e2666; text-decoration: none; display: block;"
                                :href="`mailto:${formValues.email}`"> {{ formValues.email.toLowerCase() }} </a>
                            </td>
                          </tr>
                          <tr>
                            <td width="156" height="20" bgcolor="white" align="left" valign="middle" cellpadding="0"
                              cellspacing="0"
                              style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, sans-serif; line-height: 20px; font-size: 20px; display: block;">
                              <a style="padding: 0; margin: 0; border: 0; font-family: Nunito, Calibri, sans-serif; line-height: 20px; font-size: 12px; font-weight: 700; color: #2e2666; text-decoration: none; display: block;"
                                href="https://cloudandthings.io" target="_blank">
                                https://cloudandthings.io</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="75" height="111.55" bgcolor="white" cellpadding="0" cellspacing="0" border="0"
                      align="center" valign="middle" style="padding: 0; margin: 0; border: 0; display: block;">
                      <img src="end-bracket.png" width="75" height="111.55"
                        style="width: 75px; height: 111.55px; display: block; vertical-align: middle; align: center;">
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="width: 100%; height: 20px; display: block;">
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { saveAsPng } from 'save-html-as-image';


export default {
  name: "Form",
  components: {},
  data: () => {
    return {
      formValues: {
        email: "",
        name: "",
        surname: "",
        phone: "",
        position: ""
      }
    }
  },

  mounted() {

    // This 💩 is not working. We are only getting the email attribute back.
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log("SignatureGenerator:mounted:currentAuthenticatedUser", user)
        return Auth.userAttributes(user);
      })
      .then(_attributes => {
        console.log(_attributes);
      })
      .catch(error => console.log("SignatureGenerator:mounted:currentAuthenticatedUser:error", error))

    Auth.currentSession()
      .then(data => {
        const idToken = data.getIdToken()

        console.log("SignatureGenerator:mounted:currentSession", data);
        console.log("SignatureGenerator:mounted:getIdToken", idToken);

        this.formValues.email = idToken.payload.email;
      })
      .catch(error => console.log("SignatureGenerator:mounted:currentSession:error", error));
  },

  methods: {
    fontSize(name, surname) {
      const len = name.length + surname.length
      console.log("Len: ", typeof (name))
      console.log("Name: ", name)
      console.log("Surname: ", surname)

      return len > 12 ? 25 : 30
    },
    handleSubmit() {
      var node = document.getElementById('signature');
      saveAsPng(node, {  filename: 'signature', printDate: true }, { pixelRatio: 1 });
    }
  }
};
</script>