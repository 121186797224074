import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/SignatureGenerator.vue'

import { SocialAuth, SocialAuthCallback } from '../components';
import { Auth } from 'aws-amplify';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth',
    name: 'Authenticator',
    component: SocialAuth
  },
  {
    path: '/authcb',
    name: 'AuthenticatorCallback',
    component: SocialAuthCallback
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    try {
     await Auth.currentAuthenticatedUser();
      next();
    }catch (error) {
      console.log("NotAuthed", error);
      next({path: "/auth"})
    }
  }
  next();
}) 

export default router
