<template>
  <div>Continuing to sign you in....</div>
</template>

<script>

import * as AmplifyModules from "aws-amplify";

export default {
  name: "Callback",
  data() {
    return {
      signedIn: false,
    };
  },
  beforeCreate() {
    AmplifyModules.Hub.listen("auth", (data) => {
      console.log("SocialAuthCallback", data);

      if (data.payload.event == "signIn") {
        this.$router.push({ path: "/" });
      }
    });
  },
};
</script>